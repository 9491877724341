
import { defineComponent, ref } from '@nuxtjs/composition-api'

/** 想定フレームレート */
const SCROLL_FPS = 20

/** タッチデバイスに対応した水平スクロールコンポーネント */
const dHorizontalScroll = defineComponent({
  name: 'DHorizontalScroll',
  props: {
    /** 吸着機能有効フラグ */
    enabledFix: {
      type: Boolean,
      default: false
    },
    /** 吸着間隔 */
    fixStep: {
      type: Number,
      default: 0
    },
    /** 吸着時インジケータのステップ数 */
    stepCount: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    /** スクロール領域 */
    const scrollContainer = ref<Element>()

    /** スクロール開始状態 */
    let isScrollStarted = false
    /** スクロール開始位置 */
    let lastMousePositionX = 0
    /** スクロール中状態 */
    let isScrolling = false
    /** 現在のスクロールステップ */
    const currentStep = ref(0)

    const onMouseDown = (e: MouseEvent) => {
      isScrollStarted = true
      lastMousePositionX = e.x
    }
    const onMouseMove = (e: MouseEvent) => {
      if (!isScrollStarted) {
        return
      }

      isScrolling = true

      if (scrollContainer.value) {
        const { scrollLeft } = scrollContainer.value
        const scrollDelta = e.x - lastMousePositionX
        lastMousePositionX = e.x
        scrollContainer.value.scrollTo(scrollLeft - scrollDelta, 0)
      }
    }
    const onClick = (e: MouseEvent) => {
      // スクロール後にClickイベントが発火してしまうのでキャプチャフェーズで伝搬を止める
      if (isScrolling) {
        isScrolling = false
        e.stopPropagation()
      }
    }

    /**
     * スクロール状態のリセット
     * 吸着機能が有効の場合は指定位置までスムーズにスクロールする
     */
    const resetScrolling = () => {
      isScrollStarted = false
      if (props.enabledFix && scrollContainer.value) {
        const { scrollLeft } = scrollContainer.value
        currentStep.value = Math.round(scrollLeft / props.fixStep)
        scrollContainer.value.scrollTo({
          left: currentStep.value * props.fixStep,
          behavior: 'smooth'
        })
      }
    }

    /**
     * スクロールリセットのトライ処理
     *
     * スクロール状態を監視してスクロールが止まってたらresetScrollingを呼び出す
     *
     * NOTE: isScrollingがtrueの場合はfalseにし、次フレームでtrueに戻っているか
     * チェックすることでスクロール状態が継続しているかを判定する
     */
    const tryResetScrollingNextFrame = () => {
      setTimeout(() => {
        if (isScrolling) {
          isScrolling = false
          tryResetScrollingNextFrame()
        } else {
          resetScrolling()
        }
      }, 1000 / SCROLL_FPS)
    }
    const onScroll = () => {
      isScrolling = true

      if (!isScrollStarted) {
        isScrollStarted = true
        tryResetScrollingNextFrame()
      }
    }

    return {
      scrollContainer,
      currentStep,
      onMouseDown,
      onMouseMove,
      onClick,
      resetScrolling,
      onScroll
    }
  }
})
export default dHorizontalScroll
